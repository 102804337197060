import React from "react";
import { Container } from "react-bootstrap";

import NavBar from "./NavBar";
import BottomBar from "./BottomBar";

const HowToUse = () => {
  return (
    <div className="has-bottom-bar">
      <NavBar type="white" />
      <BottomBar />
      <Container className="blue-text how-to-use-container">
        <h1 className="heading-m pt-4 pb-1 mb-0">За возач</h1>
        <p className="heading-subtext">
          Објавете од каде до каде патувате и намалете ги трошоците на 0!
        </p>
        <div className="text-center pb-4">
          <img src="/images/how-to-use-hero.jpg" />
        </div>
        <h3 className="heading-xxs">1. Објавете превоз</h3>
        <p>
          Kликнете на плусот и следете ги чекорите, не заборавајте да ја
          вклучите опцијата <strong>“Добивај предлог локација”</strong>, доколку
          би сакале да добивате предлози со конкретни адреси за преземање и
          пристигање на патниците. (Пример: патникот сака да го преземете од
          неговиот дом)
        </p>
        <h3 className="heading-xxs">2. Патниците резервираат онлајн</h3>
        <p>
          Откако патниците ќе резервираат во делот <strong>“патувања” </strong>
          ќе може да ги погледнете локациите за подигање и оставање на патниците
          како и нивниот телефонски број. Исто така тука можете да ги погледнете
          и прифатите/одбиете предлозите на патниците.
        </p>
        <h3 className="heading-xxs">
          3. Потврдете кога ќе пристигнете на локацијата за преземање на
          патниците.
        </h3>
        <p>
          Кога ќе пристигнете на локацијата за преземање на патниците/патникот
          не заборавајте да притиснете на копчето <strong>"Стигнав"</strong> кај
          соодветниот патник/локација. Овој чекор е особено важен доколку
          примате плаќања онлајн како да би можеле да ве заштитеме и да ги
          добиете парите иако патникот не се појави.
        </p>
        <h3 className="heading-xxs">4. Патувате заедно</h3>
        <p>
          Откако најдовте патници, Ви посакуваме среќен пат и милион безгрижни
          патувања.
        </p>

        <h3 className="heading-xxs">5. Исплата</h3>
        <p>
          Можете да изберете патниците да ви платат во автомобил за време на
          патувањето или пак онлајн преку нашата апликација. Доколку изберете
          онлајн вашите пари ќе ви бидат на располагање кога патникот ќе потврди
          дека пристигнал на својата дестинација или во рок од 1-2 работни дена
          доколку патникот заборавил да потврди дека пристигнал на својата
          локација или не се појавил воопшто.
        </p>
        <h1 className="heading-m pt-4 pb-1 mb-0">За патник</h1>
        <p className="mb-4 heading-subtext">
          Внесете од каде до каде патувате и патувајте најудобно за минимален
          износ!
        </p>
        <h3 className="heading-xxs">1. Пребарај превоз</h3>
        <p>
          Внесете го градот од каде поаѓате и градот на пристигање како и денот
          кога ви е потребен превозот. Кликнете побарај. Сега може да го
          одберете патувањето кое најмногу ви одговара. Не заборавајте да
          изберете <strong>“ПРЕДЛОГ ЛОКАЦИЈА НА ПОДИГАЊЕ”</strong> и
          <strong>“ПРЕДЛОГ ЛОКАЦИЈА НА ОСТАВАЊЕ”</strong> доколку имате
          специфични барања од каде би сакале да ве подигне/остави возачот.
          Доколку испратите предлог најпрвин возачот треба да го прифати вашиот
          предлог за да се потврди вашата резервација.
        </p>
        <h3 className="heading-xxs">2. Плаќање</h3>
        <p>
          Прво треба да го надополните вашиот паричник на апликацијата.
          Надополнетите средства ги користите за плаќање на резервација или
          плаќање на возачот. Може да платите за време на патувањето во кеш или
          онлајн преку нашата апликација во зависност од начинот на плаќање што
          го одбрал возачот.
        </p>
        <h3 className="heading-xxs">3. Откако резервиравте</h3>
        <p>
          Откако резервиравте можете да ја погледнете вашата резервација во
          делот <strong>“патувања”</strong> каде ќе може да му пишите порака на
          возачот.
        </p>
        <h3 className="heading-xxs">
          4. Потврдете кога ќе пристигнете на вашата дестинација.
        </h3>
        <p>
          Кога ќе пристигнете на вашата дестинација не заборавајте да притиснете
          на копчето <strong>"Стигнав"</strong> кај соодветното патување. Овој
          чекор е особено важен доколку плаќате онлајн бидејќи парите ќе му
          бидат префрлени на возачот откако ќе го направите овој чекор или
          поминат 24ч од вашето пристигање.
        </p>
        <h3 className="heading-xxs">5. Патувате заедно</h3>
        <p>
          Откако возачот ве зеде, ви посакуваме среќен пат и милион безгрижни
          патувања.
        </p>
        <h3 className="heading-xxs">6. Патувањето не се реализира</h3>
        <p>
          Доколку возачот не се појави или патувањето не се реализира, кликнете
          на копчето <strong>"Превозот не се реализира"</strong>. Каде што ги
          пишувате причините поради кои возењето не се реализира и доколку
          причините се оправдани(Пример: возачот не се појавува), парите ќе ви
          бидат вратени во рок од неколку работни дена. Имате рок од{" "}
          <strong>24ч</strong> по завршувањето на патувањето да го направите
          ова.
        </p>
      </Container>
    </div>
  );
};

export default HowToUse;
