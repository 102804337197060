import React from "react";
import { Container } from "react-bootstrap";

import NavBar from "./NavBar";
import BottomBar from "./BottomBar";

const PrivacyPolicy = () => {
  return (
    <div className="has-bottom-bar">
      <NavBar type="white" />
      <BottomBar />
      <Container className="blue-text">
        <h1 className="heading-s pt-4 pb-4 mb-0">
          Услови и правила на користење
        </h1>
        <div className="privacy-policy-container">
          <div>
            <h3 className="heading-xs">1. Услови за користње</h3>
            <h4 className="heading-xxs">1.1 Цел и дефиниции</h4>
          </div>
          <p>
            Општите услови за користење важат за сите услуги обезбедени од РАЈД
            ШЕР увоз-извоз ДОО Прилеп (компанија регистрирана во Северна
            Македонија со официјално седиште на Сашо Китаноски бр.89 Прилеп
            („rideshare“). <br />
            <br /> Rideshare поседува и управува со веб-страницата
            www.rideshare.mk  Оваа интернет страница нарекува се „Сајт“. <br />
            <br /> Дефиниции <br />
            <br />
            <ul>
              <li>
                „Споделување превоз“ е заедничко патување, т.е. споделување на
                возилото за возење со возачот кој го превезува патникот во
                замена за одредено учество во трошоците;
              </li>
              <li>
                „rideshare“ е РАЈД ШЕР увоз-извоз ДОО Прилеп, компанија која ја
                одржува страницата за заеднички патувања и сите нејзини партнери
                или групни компании или поврзани компании;
              </li>
              <li>
                „Услови“ се Општите услови, вклучувајќи го Кодексот на
                однесување и Политиката за заштита на личните податоци;
              </li>
              <li>
                „Учество во трошоци“ е износот договорен помеѓу Возачот и
                Патникот за возењето што патникот го плаќа како учество во
                трошоците на возењето;
              </li>
              <li>
                „Возач“ е лице кое ја користи страницата за да постави понуда за
                заедничко патување со Патникот и за негов превоз до дадената
                дестинација во договореното време, во замена за неговото учество
                во трошоците;
              </li>
              <li>
                „Сегмент“ е делот од патувањето што Патникот посебно го
                резервира во согласност со Условите;
              </li>
              <li>
                „Член“ е патник, возач или кој било друг корисник на страницата;
              </li>
              <li>
                „Патник“ е лице кое ја прифатило понудата на Возачот за возење;
              </li>
              <li>
                „Услуга“ значи секоја услуга што ја дава Сајтот на кој било
                член;
              </li>
              <li>
                „Сајт“ значи rideshare.mk и други апликации, вклучувајќи ги сите
                микро-страници или под-страници понудени од која било од тие
                веб-локации;
              </li>
              <li>
                „Возење“ е заедничко патување договорено помеѓу возачот и
                патникот преку локацијата и овие услови го вклучуваат и
                Квалификациското возење;
              </li>
              <li>
                „Корисничка сметка“ е сметка на страницата отворена од член и се
                користи за пристап до Услугата обезбедена од страната;
              </li>
              <li>
                „Возило“ е возилото што го нуди Возачот за заедничко патување.
              </li>
            </ul>
          </p>
          <p>
            <h4 className="heading-xxs">1.2 Прифаќање на условите</h4>
            Овие услови важат за сите видови на користење на страницата. Со
            користење на страницата, членот целосно ги прифаќа Условите, дури и
            ако не отвори корисничка сметка.
            <br /> <br />
            Пристапот до Услугите нема да биде возможен доколку Условите не се
            прифатат во целост. Членовите немаат право да прифатат само дел од
            Условите. Сите членови се согласуваат да ги почитуваат условите од
            Повелбата за добро однесување и прифаќаат дека нивните лични
            податоци се обработуваат во согласност со Политиката за заштита на
            личните податоци.
            <br />
            <br />
            Договорот за обезбедување услуги за споделување транспорт е помеѓу
            Возачот и патникот и rideshare не е договорна страна на овие
            аранжмани или на договорот меѓу нив.
            <br />
            <br />
            Во случај членот да не се придржува кон некој од утврдените Услови,
            rideshare го задржува правото да ја повлече корисничката сметка на
            тој член и да ги суспендира или одбие сите Услуги на тој Клиент без
            претходно известување.
            <br />
            <br />
            Целта на овие Услови е да се создадат обврзувачки права и обврски
            помеѓу членовите.
          </p>
          <p>
            <h4 className="heading-xxs">
              1.3 Измена на условите, страницата и услугата
            </h4>
            rideshare го задржува правото да ги промени Условите во секое време.
            Покрај тоа, rideshare може во секое време да ги промени или дополни
            Условите што ги обезбедува преку страницата, функционалноста на
            страницата и/или „изгледот и чувството“ на страницата без претходна
            најава, додека не презема одговорност кон членовите за последиците
            од таквите промени или дополнувања.
            <br />
            <br />
            Сите промени на страницата, услугите или условите ќе бидат ефективни
            веднаш штом ќе бидат објавени на страницата.
            <br />
            <br />
            Со користење на која било Услуга понудена преку страницата по
            објавувањето на изменетите Услови, членовите автоматски ги прифаќаат
            сите изменети Услови. Промените не се однесуваат на резервации
            направени пред објавувањето на променетите Услови.
          </p>
          <p>
            <h3 className="heading-xs">2. Користење на услуги</h3>
            <h4 className="heading-xxs">
              2.1 Корисничка сметка и точност на податоците
            </h4>
            За да ги користи услугите, секој член мора да отвори корисничка
            сметка и да се согласи да ги обезбеди сите лични информации што ги
            бара rideshare. Од членовите посебно се бара да го дадат своето
            лично име, презиме, возраст, титула, валиден телефонски број и
            адреса на е-пошта, т.е. е-пошта. Веб-страницата може да ја користат
            само лица кои имаат над 18 години за време на регистрацијата.
            <br />
            <br />
            Членовите се согласуваат и прифаќаат дека сите информации што ги
            даваат на BlaBlaCar во моментот на отворање на нивната корисничка
            сметка и во секое друго време ќе бидат вистинити, целосни и точни во
            сите погледи. Членовите исто така се согласуваат дека сите
            информации што ги доставуваат до rideshare или објавуваат на
            страницата во врска со секое возење, возило или заедничко патување
            ќе бидат вистинити, точни и целосни.
            <br />
            <br />
            rideshare нема да сноси одговорност во случај какви било информации
            дадени од која било членка (вклучувајќи, заради избегнување сомнеж,
            која било членка) да се нецелосни, неточни, погрешни или лажни.
            <br />
            <br />
            Освен ако не е поинаку договорено со rideshare, членовите имаат
            право на само една корисничка сметка. Никој не може да отвори
            корисничка сметка во име на друго лице или да се претставува како
            друго лице.
            <br />
            <br />
            Секое прекршување на овие Услови ќе резултира со итно суспендирање
            на корисничката сметка на членот и на тој член може да му биде
            забранет понатамошниот пристап до сите услуги.
          </p>
          <p>
            <h4 className="heading-xxs">
              2.2 Некомерцијална дејност и статус на rideshare
            </h4>
            Сајтот и услугите се строго ограничени на овозможување на услугата
            за возачите и патниците за заедничко патување во приватен аранжман.
            Услугите не смеат да се користат за давање или прифаќање понуди за
            заеднички патувања за профит или во кој било комерцијален или
            професионален контекст.
            <br />
            <br />
            Возачите не можат да профитираат од ниту едно патување. Услугата и
            споделувањето на трошоците може да се користат само за покривање на
            трошоците направени од Возачот и не може да се користат за да се
            оствари каква било добивка за Возачот. Возачот нема право да
            остварува профит врз основа на износот на придонесот во трошоците,
            видот на патувањата што ги нуди Возачот, зачестеноста на тие
            патувања или бројот на патници што ги носи. Ова се однесува на сите
            активности, аранжмани и услуги резервирани преку страницата и за
            сите дополнителни услуги или активности што може да се договорат
            помеѓу Возачот и патникот.
            <br />
            <br />
            Соодветно на тоа, РАЈД ШЕР може привремено или трајно да ја затвори
            сметката на кој било член кој користи професионално (изнајмување
            возило со возач, такси итн.), службено или сервисно возило, имајќи
            предвид дека на тој начин членот би заработил приход преку услугите
            понудени од нашата платформа. Во случај на сомневање, РАЈД ШЕР може
            да побара од Возачот да достави копија од неговата возачка дозвола
            или кој било друг документ што докажува дека Возачот има право да
            понуди превоз со дадено возило на нашата платформа и дека не
            остварува никаква добивка.
            <br />
            <br />
            Возачот не смее да обезбедува дополнителни услуги на кој било патник
            заради профит или добивка без ограничување, испорака на пакети,
            чекање, дополнителни застанувања за паузи.
            <br />
            <br />
            Сите патувања, почетни точки и дестинации мора да се договорат
            однапред преку страницата. Возачите не смеат да преземаат патници од
            која било локација што претходно не била договорена со патникот
            преку страницата.
            <br />
            <br />
            Одговорноста на rideshare е ограничена во согласност со овие услови,
            а rideshare особено не е одговорна за какво било прекршување на кој
            било договор меѓу членовите или за какво било прекршување на овие
            Услови од кој било член, вклучително и ако некој возач (спротивно на
            овие услови) нуди услуги преку Локација во професионален или
            комерцијален аранжман. Членовите мора да бидат претпазливи и да се
            погрижат Услугите да не се обезбедуваат на комерцијална основа.
            <br />
            <br />
            Статус на rideshare
            <br />
            <br />
            Ниту rideshare ниту Сајтот не обезбедуваат транспортни услуги.
            Веб-страницата е комуникациска платформа за членовите со цел
            меѓусебен договор. rideshare нема никаква врска со патувањата,
            дестинациите или тарифите за час што се договорени. Договорот за
            заедничко патување е склучен помеѓу Возачот и Патникот. rideshare не
            е одговорна во каков било договор или трансакција помеѓу членовите и
            rideshare не е одговорен за каква било ситуација што може да се
            појави во врска со резервации помеѓу членовите. rideshare не
            дејствува и нема да дејствува како претставник на кој било член.
            <br />
            <br />
            Секое прекршување на овие Услови ќе резултира со итно суспендирање
            на корисничката сметка на членот и на тој член може да му се одбие
            понатамошен пристап до сите услуги.
          </p>
          <p>
            <h4 className="heading-xxs">2.3 Видови резервации и плаќање</h4>
            rideshare им нуди на своите членови услуга за резервации (во
            натамошниот текст: „Сервис за резервации“), со цел да им олесни на
            патниците да резервираат места. Сепак, до степенот што го дозволува
            применливиот закон, ништо во овие Услови не наметнува никаква
            одговорност на rideshare кон членовите доколку се појави некој од
            тие ризици.
            <br />
            <br />
            Услугата за резервација е резервирана за Возењата опишани подолу (во
            натамошниот текст: „Квалификациони возења“), термин кој го вклучува
            Делот, кога е применливо во согласност со следните параграфи):
            <br />
            <br />
            <ul>
              <li>
                Еднократно патување за кое е направена специјална реклама на
                интернет страницата и која се состои од ад хок патување кое се
                одвива само еднаш на одреден датум и во одредено време за дадена
                појдовна точка и дестинација
              </li>
              <li>Што не е прекугранично.</li>
            </ul>
            Давателот на услугата го задржува правото да го ограничи бројот и на
            членови кои ги исполнуваат условите за Услугата за резервации за
            секое квалификувано возење.
            <br />
            <br />
            Возачот дава детали за неговото или нејзиното квалификувано возење
            на страницата, наведувајќи го датумот и времето за точките на
            поаѓање и одредиште, износот на придонесот за трошоци по седиште и
            сите други релевантни услови за патување.
            <br />
            <br />
            Патникот резервира едно или повеќе места во возилото за тоа
            Квалификациско возење со кликнување на копчето „Резервирај“ (во
            натамошниот текст: „Резервација“).
            <br />
            <br />
            rideshare потоа ќе испрати потврда по е-пошта до секој возач и
            патник со која се потврдува резервацијата (во натамошниот текст:
            „Потврда за резервација“). Откако ќе се испрати потврдата за
            резервација, резервацијата е завршена.
            <br />
            <br />
            Членовите се согласуваат дека возачите и патниците нема да
            контактираат со rideshare за каков било вид трансакција, вклучувајќи
            откажувања, промени во последен момент, неприкажување на возачот или
            патникот или неплаќање на дел од трошоците. Возачот е особено
            одговорен за наплата на уплатата од патникот за време на патувањето.
            <br />
            <br />
            rideshare нема да контактира со ниту една страна и нема да преземе
            никакви чекори во врска со организацијата на резервацијата. Услугата
            е организирана од возачи и патници.
            <br />
            <br />
            Ве молиме запомнете дека rideshare го задржува правото да промени
            кој било аспект на страницата или нејзините услуги, што може да
            вклучи додавање нови услуги (што може да предизвика наплата) или
            прекинување на некои услуги.
            <br />
            <br />
            Резервацијата е лична за возачот и патникот. Идентитетот на возачот
            и патникот мора да биде ист како идентитетот што секој од нив го дал
            на rideshare. Членот има право да го откаже возењето доколку
            идентитетот на возачот или патникот не е ист со идентитетот наведен
            на страницата.
            <br />
            <br />
            Возачот се обврзува на rideshare и на патникот/патниците да го
            изведат возењето што тој или таа го објавил на страницата за да го
            сподели со нив на договорениот датум, време и место. Возачот, исто
            така, се обврзува на rideshare и на патникот/патниците да се
            погрижат неговото или нејзиното возило да го има огласеното место за
            седење и за кое се направени резервации.
          </p>
          <p>
            <h4 className="heading-xxs">2.4 Обврски на возачите и патниците</h4>
            Обврска на возачите <br /> <br />
            Возачот се согласува на следново: <br /> <br />
            <ul>
              <li>
                Возачот ќе се појави во договореното време и место со наведеното
                возило;
              </li>
              <li>
                Возачот веднаш ќе ги информира сите патници за какви било
                промени во Патувањето. Ако еден или повеќе патници направиле
                резервации и Возачот одлучи да го промени патувањето на кој било
                начин, Возачот се обврзува да контактира со сите патници кои
                направиле резервации во врска со тоа патување за да добие
                согласност од сите патници во врска со промената. Доколку
                патникот одбие да се согласи со промената, тој има право на
                целосна наплата и без обврска да плати каков било надомест на
                Возачот;
              </li>
              <li>
                Возачот мора во секое време да ја почитува Повелбата за добро
                однесување и да ги почитува сите патни правила и закони на
                земјите во кои вози и вози безбедно;
              </li>
              <li>
                Возачот мора да го чека патникот на местото на поаѓање најмалку
                15 минути по договореното време (сепак, се очекува патникот да
                биде точен).
              </li>
            </ul>
            Патникот се согласува на следново: <br /> <br />
            <ul>
              <li>
                Патникот ќе пристигне во договореното време и на договореното
                место во согласност со договорот со Возачот;
              </li>
              <li>
                Патникот веднаш ќе го извести Возачот или rideshare доколку мора
                да го откаже патувањето;
              </li>
              <li>
                Патникот во секое време ќе ја почитува Повелбата за добро
                однесување и ќе се придржува кон сите патни правила и закони на
                земјите во кои вози и безбедно вози;
              </li>
              <li>
                Патникот се согласува да го чека пристигнувањето на Возачот на
                местото на поаѓање најмалку 15 минути по договореното време;
              </li>
              <li>
                Патникот се согласува да му плати на Возачот дел од трошоците.
              </li>
            </ul>
            Доколку патникот или возачот не се придржува кон некој од овие
            услови или други услови, rideshare го задржува правото да зачува
            информации поврзани со прекршување на таа обврска, да ги објави или
            открие на  онлајн  профилот на членот и да го суспендира или прекине
            пристапот до Сајтот за тој член.
          </p>
          <p>
            <h4 className="heading-xxs">2.5 Сигурност</h4>
            За учество во превозот, возилата треба да: <br />
            <br />
            <ul>
              <li>бидат регистрирани,</li>
              <li>
                {" "}
                имаат важечка сообраќајна дозвола или важечка потврда за пробно
                возење или налог за пробно возење и користење на возило со
                пробна пренослива метална таблица и бидат означени со пропишани
                регистарски таблици.{" "}
              </li>
            </ul>
            Возачот на возилото е обврзан со возилото да носи сообраќајна
            дозвола или потврда за пробно возење односно налог за пробно возење
            и користење на возило со пробна пренослива метална таблица; <br />
            <br />
            Возачите учесници во превозот задолжително треба да поседуваат
            возачка дозвола за соодветната категорија на возило со кое го вршат
            превозот; <br />
            <br />
            Возилата кои се користат за превоз, во периодот од 15 ноември до 15
            март задолжително треба да поседуваат пропишана зимска опрема.{" "}
            <br />
            <br />
            Возачите кои учествуваат во превозот се одговорни за сите обврски по
            однос на исправност на возилото, поседување на соодветна возачка
            дозвола како и техничка исправност на нивните возила, предвидени
            согласно позитивните законси прописи во Р.С.Македонија <br />
            <br />
            Возачот не смее да зема пари од патникот освен за поделба на
            трошоците и Возачот не смее да остварува профит под никакви
            околности. <br />
            <br />
            Затоа, Возачот се обврзува да ги пресмета неговите трошоци (гориво,
            патарини, одржување, поправки, потврда за техничка исправност на
            возилото, амортизација и осигурување на возилото) и гарантира дека
            вкупниот износ на учество во трошоците што ги бара од неговите
            патници не му го носи каква било добивка. <br />
            <br />
            Ако Возачот оствари профит или ако осигурителот не признае или одбие
            да прифати какво било побарување што настанало за време на
            патувањето поради која било друга причина, Возачот ќе биде одговорен
            за финансиските последици, загубите и штетите што ќе настанат, а
            rideshare во никој случај нема да да биде одговорен пред возачот или
            пред патникот. <br />
            <br />
            rideshare го задржува правото веднаш да ја суспендира сметката на
            корисникот, како и прикажаните пари и да ги информира надлежните
            органи за секоја професионална активност. <br />
            <br />
          </p>
          <p>
            <h4 className="heading-xxs">
              2.6 Решавање на спорови меѓу членовите
            </h4>
            rideshare ќе им обезбеди на своите членови  онлајн  услуга за
            решавање спорови. Оваа услуга не е обврзувачка. rideshare нема
            обврска да се обидува да ги реши споровите. rideshare ја нуди оваа
            услуга по сопствена дискреција и може да ја повлече во секое време.
            <br />
            <br /> Доколку некој спор не е решен, rideshare го задржува правото
            да ја задржи секоја сума платена од патникот додека не се постигне
            пријателски договор помеѓу патникот и возачот или додека не се
            донесе конечна судска одлука.
          </p>
          <p>
            <h3 className="heading-xs">3. Одговорност</h3>
            rideshare не е страна на каков било договор помеѓу Возачот и
            патникот и не е одговорен пред ниту една страна освен ако загубата
            или штетата е предизвикана од невнимание на rideshare.
            <br />
            <br />
            rideshare нема да биде одговорен за каква било загуба или штета што
            ќе настане како резултат на:
            <br />
            <br />
            <ul>
              <li>
                сите неточни или нецелосни информации обезбедени од членот;
              </li>
              <li>
                откажување на патувањето од страна на возачот или патникот;
              </li>
              <li>
                неплаќање на учество во трошоците (за бесплатни услуги без
                резервација);
              </li>
              <li>
                каква било измама, лажно претставување или прекршување на
                должноста или прекршување на кој било од овие Услови од страна
                на Возачот или патникот пред, за време или по патувањето.
              </li>
            </ul>
            rideshare нема да биде одговорен пред ниту еден член за каква било
            деловна, финансиска или економска загуба или каква било
            последователна или индиректна загуба како што е губење на угледот,
            губење на бизнисот, загуба на добивка, загуба на очекуваните заштеди
            или губење на можности, кои произлегуваат како резултат на услугите
            што ги обезбедува rideshare (без разлика дали претрпеле или
            настанале како резултат на невнимание на rideshare или на друг
            начин) освен во случај на измама, намерно прикривање или кражба.
            <br />
            <br />
            Ништо во овој Договор не ја ограничува или исклучува одговорноста на
            rideshare за смрт или материјална и нематеријална штета предизвикана
            од невнимание на rideshare. Со оглед на тоа дека Возачот се бара да
            има валидно осигурување за покривање на Патувањето и дека услугата
            на rideshare е ограничена само на обезбедување можност за контакт,
            т.е. поврзувајќи ги возачите и патниците и не може да предвиди
            никакво патување, членовите се согласуваат дека горенаведените
            ограничувања на одговорноста на rideshare се разумни.
          </p>
          <p>
            <h3 className="heading-xs">4. Општи услови</h3>
            <h4 className="heading-xxs">
              4.1 Суспендирање или прекинување на пристапот до страницата
            </h4>
            Во случај членот да не ги почитува Условите, во целост или само
            некои делови, членот потврдува и прифаќа дека rideshare може во
            секое време, без претходно известување, да ги прекине или
            суспендира, привремено или трајно, сите услуги или дел од услугите
            или вашиот пристап до страницата (вклучувајќи и кориснички сметки).{" "}
            <br />
            <br />
            <h4 className="heading-xxs">4.2  Интелектуална сопственост</h4>
            Форматот и содржината на оваа страница се заштитени со авторски
            права и rideshare ги задржува сите права во врска со нејзините
            авторски права без разлика дали се во наша сопственост или
            лиценцирани. <br />
            <br /> Сите права на сите регистрирани и нерегистрирани трговски
            марки (без разлика дали се во сопственост или лиценцирани од
            rideshare) се задржани. <br /> <br /> Оваа интернет страница или кој
            било дел од неа не смее да се репродуцира, дуплира, копира, продава,
            препродава, посетува или на друг начин да се искористува за
            комерцијални цели без изречна писмена согласност од rideshare. Ниту
            една страна нема право систематски да извлекува и/или да користи
            делови од содржината на страницата без изречна писмена согласност од
            rideshare.
            <br />
            <br />
            <h4 className="heading-xxs">
              4.3  Содржина на страницата објавена од членовите
            </h4>
            Корисниците на оваа страница се обврзуваат да не објавуваат каква
            било клеветничка, погрешна или навредлива содржина или содржина што
            ги повредува сите права на интелектуална сопственост на други (на
            пр. авторски права). Целата таква содржина е против политиката на
            rideshare и rideshare не прифаќа никаква одговорност во врска со
            таквата содржина. Корисникот кој поставува таква содржина ќе биде
            лично одговорен за какви било штети или други обврски што
            произлегуваат од неа и го ослободува rideshare од секоја одговорност
            што може да произлезе како резултат на таквата содржина. Меѓутоа,
            штом rideshare забележи таква содржина, ќе стори сé за да ја
            отстрани од страницата што е можно поскоро.
          </p>
          <p>
            <h3 className="heading-xs">5. Закон и надлежност</h3>
            Овие услови се регулирани со законите на Република Северна
            Македонија. Сите спорови кои произлегуваат во врска со овие Услови
            се во надлежност на надлежните судови.
            <br />
            <br /> Овие Општи услови и преддоговорни информации се усвоени и
            стапуваат на сила на 20.05.2024 годинa.
          </p>
          <p>
            <h3 className="heading-xs">6. Плаќање</h3>
            Надоместокот за резервација кој што е објавен на сајтот на rideshare
            е прикажани со вклучен ДДВ.
            <br />
            <br />
            Плаќање со платежна картичка:
            <br />
            Можете да платите со користење на платежните картички на Visa,
            MasterCard. Картичката мора да биде одобрена за онлајн (интернет)
            плаќање од страна на банката која ја издала.
            <br />
            <br />
            Во текот на процесирање на онлајн нарачката, по одбирање на начинот
            на плаќање ќе бидeте редиректирани до страната на халкбанка која е
            сигурна и заштитена за ваков вид плаќања. Податоците за вашата
            картичка во ниту еден момент не се достапни во нашиот систем.
            <br />
            <br />
            На оваа страница потребно е да ги внесете следните податоци:
            <br />
            - Број на платежната картичка
            <br />
            - Име и презиме на носителот на картичката
            <br />
            - Датумот на важност на картичката
            <br />
            - CVV2/CVC2 код, кој може да го прочитате на вашата картичка
            <br />
            <br />
            Податоците кои се внесуваат се наменети исклучиво за комуникација
            помеѓу корисникот на картичката и Халк Банка/, додека интернет
            трговецот нема никаков увид или пристап до податоците кои се
            пренесуваат.
            <br />
            <br />
            <strong>
              Напомена:
              <br />
              Во случај на откажување на превозот вашите средства ќе ви бидат
              рефундирани во рок до 14 денови.
            </strong>
          </p>
        </div>
      </Container>
    </div>
  );
};

export default PrivacyPolicy;
